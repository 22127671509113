// extracted by mini-css-extract-plugin
export var articleWrapper = "k_n2";
export var articleText = "k_n3 d_dt d_cr";
export var header = "k_n4 d_Y d_v";
export var headerImageWrapper = "k_n5 d_bc d_Z d_7 d_6 d_3 d_8 d_bj d_bQ";
export var headerGradient = "k_n6 d_bc d_Z d_7 d_6 d_3 d_8";
export var headerGradientOverlay = "k_n7 d_bc d_Z d_7 d_6 d_3 d_8";
export var headerContentWrapper = "k_n8 d_by d_bN d_bC d_bH d_bk d_Y";
export var headerContentWrapperAlt = "k_n9 d_by d_bN d_bC d_bH d_bk d_Y";
export var contentWrapper = "k_pb d_v";
export var dateTag = "k_pc d_cd d_cq d_dp d_v";
export var icon1 = "k_pd d_cN";
export var icon2 = "k_pf d_cN d_cH";
export var tagBtn = "k_pg d_b0 d_cC d_dn d_dz";
export var headerText = "k_ph d_v d_cd d_cr d_bV";
export var center = "k_pj d_dv";
export var videoIframeStyle = "k_pk d_d4 d_v d_G d_bx d_b0 d_Q";
export var articleImageWrapper = "k_pl d_cr d_Y";
export var articleImageWrapperIcon = "k_pm d_cr d_Y";
export var articleRow = "k_pn d_bC";
export var quoteWrapper = "k_gQ d_bB d_bN d_cr";
export var quoteBar = "k_pp d_G";
export var quoteText = "k_pq";
export var authorBox = "k_pr d_v";
export var authorRow = "k_ps d_bC d_bV d_cx";
export var separator = "k_pt d_v";
export var line = "k_fj d_v d_fj d_cX d_cx";
export var authorImage = "k_lp d_b6 d_Y d_v d_cs";
export var authorText = "k_pv d_cg d_cs";
export var masonryImageWrapper = "k_pw";
export var bottomSeparator = "k_px d_v d_cx";
export var linksWrapper = "k_py d_cg d_dt";
export var comments = "k_pz d_bC d_cx";
export var sharing = "k_pB d_by d_bC d_bH";
export var shareText = "k_pC d_v d_dv";
export var icon = "k_pD";
export var customRow = "k_pF d_bC d_Y";
export var text = "k_pG";
export var SubtitleSmall = "k_pH";
export var SubtitleNormal = "k_pJ";
export var SubtitleLarge = "k_pK";