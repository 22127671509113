import React from 'react';
import { Link } from 'gatsby';

import { formColor } from '../../../../helper';
import ButtonEditor from '../../../EditorPanel/ManageSections/LayoutComponents/ButtonEditor';
import Image from '../../../Image';
import CustomSectionMedia from '../../../EditorPanel/ManageSections/LayoutComponents/CustomSectionMedia';
import { StoreContext } from '../../../../helpers/Store';

import SubLinks from './SubLinks';
import * as styles from './styles.module.css';

class NavLink extends React.PureComponent {
  render() {
    const positionRelative = {
      position: 'relative',
    };
    const { header } = this.props;
    const rawLink = this.props.link;
    let link;
    let targetValue = rawLink.openLinkInNewTab ? '_blank' : '_self';

    let button;
    if (rawLink.format === 'BUTTON') {
      button = (
        <ButtonEditor
          themeData={this.props.themeData}
          data={rawLink.formatData}
          text={rawLink.text || rawLink.name || rawLink.path || ''}
          buttons={this.props.buttons}
          invert={header && header.type === 'invert'}
        />
      );
    }

    let lineHeight = 1.5;
    if (rawLink.format === 'IMAGE') lineHeight = 0;
    else if (rawLink.format === 'BUTTON') lineHeight = 1.25;

    let style = {
      ...positionRelative,
      color: this.props.color,
      lineHeight,
    };
    if (this.props.isBurger) style = { ...style, fontSize: '1.25rem' };

    if (this.props.burgerWeight) style.fontWeight = this.props.burgerWeight;
    if (this.props.burgerUpper != null) style.textTransform = this.props.burgerUpper ? 'uppercase' : 'none';

    let icon;
    if (rawLink.format === 'ICON' && rawLink.formatData && rawLink.type !== 'EXTERNAL') {
      let { color } = rawLink.formatData;
      if (color) {
        color = formColor(
          { solid: color },
          false,
          1,
          undefined,
          this.props.themeData.colors,
          header && header.type === 'invert',
        ).backgroundColor;
      }
      icon = (
        <i
          className={`entypo ${rawLink.formatData.class}`}
          style={{ fontSize: rawLink.formatData.size, color }}
          aria-hidden
        />
      );
    }

    let image;
    if (['IMAGE', 'ICON'].includes(rawLink.format) && rawLink.type === 'EXTERNAL') {
      image = (
        <CustomSectionMedia
          id={rawLink.formatData?.id}
          className={rawLink.format === 'IMAGE' ? 'navbarImage' : undefined}
          imageStyleName={rawLink.format === 'IMAGE' ? 'navbarImage' : undefined}
          colors={this.props.themeData.colors}
          data={
            rawLink.format === 'IMAGE'
              ? { id: rawLink.formatData?.id, linkObj: rawLink, width: rawLink.formatData?.width }
              : { icon: rawLink.formatData, linkObj: rawLink }
          }
          images={this.props.images}
        />
      );
    } else if (rawLink.format === 'IMAGE' && rawLink.formatData?.id) {
      image = (
        <Image
          id={rawLink.formatData.id}
          alt={`Navbar_Image_${rawLink.formatData.id}`}
          className="navbarImage"
          imageStyleName="navbarImage"
          images={this.props.images}
        />
      );
    }

    const linkContent = (
      <>
        {image}
        {button}
        {icon}
        {rawLink.format === 'TEXT' &&
          ((rawLink.type === 'EXTERNAL' && (rawLink.text || rawLink.path)) ||
            ((rawLink.type === 'ARTICLE' || rawLink.type === 'FILE') && (rawLink.text || rawLink.name)) ||
            rawLink.name)}
      </>
    );

    if (rawLink.type === 'FILE') targetValue = '_blank';

    const isMain = rawLink.children && rawLink.children.length > 0;
    if (rawLink.type === 'EXTERNAL' || rawLink.type === 'FILE' || targetValue === '_blank') {
      let url = rawLink.path;
      if (rawLink.type === 'SECTION') url = `${rawLink.path}#${rawLink.sectionSlug}`;
      link = (
        <a
          href={url}
          className={`${styles[`menu${isMain ? 'Main' : ''}Link`]} ${styles.Link} ${styles.link}`}
          style={style}
          target={targetValue}
          rel="noopener noreferrer"
        >
          {linkContent}
        </a>
      );
    } else {
      let url = rawLink.path || '/';
      if (rawLink.type === 'SECTION') url = `${url}#${rawLink.sectionSlug}`;
      link = (
        <Link
          to={url}
          state={rawLink.type === 'SECTION' ? { scrollToSection: rawLink.sectionSlug } : undefined}
          className={`${styles[`menu${isMain > 0 ? 'Main' : ''}Link`]} ${styles.Link} ${styles.link}`}
          style={style}
          activeStyle={rawLink.type !== 'SECTION' ? this.props.activeStyle : undefined}
          onClick={this.props.onLinkClick}
          draggable="false"
        >
          {linkContent}
        </Link>
      );
    }

    const fontSize =
      this.props.themeData.typography.navigation.fontSize === 'Small'
        ? '0.85rem'
        : this.props.themeData.typography.navigation.fontSize === 'Normal'
        ? '1rem'
        : '1.25rem';

    const themeNavigationStyle = {
      fontFamily: this.props.themeData.typography.navigation.name,
      fontWeight: this.props.themeData.typography.navigation.weight,
      lineHeight: this.props.themeData.typography.navigation.lineHeight,
      letterSpacing: this.props.themeData.typography.navigation.letterSpacing,
      fontSize,
    };

    let divider;
    if (this.props.divider && this.props.divider > 1) {
      let div;
      switch (this.props.divider) {
        case 2:
          div = <span style={themeNavigationStyle}>&bull;</span>;
          break;
        case 3:
          div = <span style={themeNavigationStyle}>&#xFF5C;</span>;
          break;
        default:
        // Nothing here
      }

      divider = (
        <div style={{ color: this.props.color }} className={styles.divider}>
          {div}
        </div>
      );
    }

    let subLinks;
    if (isMain) {
      let marginTop;
      if (rawLink?.format === 'ICON') {
        switch (rawLink.formatData?.size) {
          case '32px':
            marginTop = '48px';
            break;
          case '72px':
            marginTop = '113px';
            break;
          case '124px':
            marginTop = '157px';
            break;
          default:
            break;
        }
      } else if (['IMAGE', 'BUTTON'].includes(rawLink?.format)) marginTop = '50px';

      subLinks = (
        <SubLinks
          onLinkClick={this.props.onLinkClick}
          themeData={this.props.themeData}
          color={this.props.subFontColor ?? this.props.color}
          activeStyle={this.props.activeSubStyle ?? this.props.activeStyle}
          links={rawLink.children}
          isBurger={this.props.isBurger}
          subColor={this.props.subColor}
          header={header}
          images={this.props.images}
          marginTop={marginTop}
          submenuWeight={this.props.submenuWeight}
          submenuUpper={this.props.submenuUpper}
        />
      );
    }

    if (subLinks && !(this.props.isBurger || this.props.showSubPageLink === rawLink.id)) {
      subLinks = <div className={styles.sublinks}>{subLinks}</div>;
    }

    const setFlex =
      (rawLink.type === 'EXTERNAL' && rawLink.format === 'ICON') || ['IMAGE', 'BUTTON'].includes(rawLink.format);

    return (
      <>
        {this.props.additionalDivider && divider}
        <div
          className={`NavLink ${styles[`menuItem${isMain ? 'Sub' : ''}`]}`}
          key={`NavigationBar_${rawLink.id}`}
          style={
            (isMain && this.props.isBurger) || rawLink.format === 'ICON'
              ? {
                  marginLeft: rawLink.format === 'ICON' ? '5px' : undefined,
                  marginRight: rawLink.format === 'ICON' ? '5px' : undefined,
                  justifyContent: isMain && this.props.isBurger ? 'center' : undefined,
                  flexWrap: isMain && this.props.isBurger ? 'wrap' : undefined,
                }
              : undefined
          }
        >
          {isMain ? (
            <div
              style={{
                width: '100%',
                maxHeight: ['IMAGE', 'BUTTON'].includes(rawLink.format) ? '45px' : undefined,
                display: setFlex ? 'flex' : undefined,
                justifyContent: setFlex ? 'center' : undefined,
              }}
            >
              {link}
            </div>
          ) : (
            link
          )}
          {!this.props.isBurger && isMain && (
            <div
              className={`${styles.Link} ${styles.icon}`}
              role="button"
              tabIndex="0"
              onKeyPress={() => this.props.onMenuLinkClick(rawLink.id)}
              onClick={() => this.props.onMenuLinkClick(rawLink.id)}
              aria-label={this.context.translate('openMenu')}
            >
              <span
                style={{ ...themeNavigationStyle, color: this.props.color, cursor: 'pointer' }}
                className="entypo icon-down-open"
              />
            </div>
          )}
          {subLinks}
        </div>
        {divider}
      </>
    );
  }
}

NavLink.contextType = StoreContext;

export default NavLink;
