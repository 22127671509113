// extracted by mini-css-extract-plugin
export var lbContainerOuter = "v_tf";
export var lbContainerInner = "v_tg";
export var movingForwards = "v_th";
export var movingForwardsOther = "v_tj";
export var movingBackwards = "v_tk";
export var movingBackwardsOther = "v_tl";
export var lbImage = "v_tm";
export var lbOtherImage = "v_tn";
export var prevButton = "v_tp";
export var nextButton = "v_tq";
export var closing = "v_tr";
export var appear = "v_ts";