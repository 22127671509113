// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "n_qd d_bC";
export var storyRowWrapper = "n_hx d_hx d_bJ";
export var storyLeftWrapper = "n_qf d_by d_bN";
export var storyWrapperFull = "n_qg d_cC";
export var storyWrapperFullLeft = "n_nN d_cC d_by d_bN d_bC";
export var contentWrapper = "n_pb d_hy";
export var storyLeftWrapperCenter = "n_qh d_hz d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "n_qj d_hF";
export var storyHeader = "n_qk d_hD d_v d_cr";
export var storyHeaderCenter = "n_hC d_hC d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "n_hB d_hB d_bx d_dv";
export var storyBtnWrapper = "n_ql d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "n_hJ d_hJ d_d0 d_v d_by d_bC";
export var imageWrapper = "n_qm d_ff d_Y";
export var imageWrapperFull = "n_qn d_v d_G d_bd d_Y";