// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "s_fQ d_fQ d_by d_bG d_bH";
export var navbarDividedRight = "s_fR d_fR d_by d_bH";
export var menuDesign6 = "s_r3 d_fM d_by d_dv d_bN d_bH";
export var menuDesign7 = "s_r4 d_fM d_by d_dv d_bN d_bH";
export var menuRight = "s_r5 d_fM d_by d_dv d_bN d_bH";
export var menuLeft = "s_r6 d_fM d_by d_dv d_bN d_bH";
export var menuCenter = "s_r7 d_fN d_fM d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "s_qv d_fN d_fM d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "s_qG d_fP d_fM d_by d_dv d_bN d_bH";
export var isBurger = "s_r8";
export var navbarItem = "s_qw d_bw";
export var navbarLogoItemWrapper = "s_fY d_fY d_bB d_bN";
export var burgerToggleVisibleOpen = "s_r9 d_gd d_bx d_Y d_br";
export var burgerToggleVisible = "s_sb d_gd d_bx d_Y d_br";
export var burgerToggle = "s_sc d_gd d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "s_sd d_gd d_bx d_Y d_br";
export var burgerInput = "s_sf d_f2 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "s_sg d_f3 d_v d_G";
export var burgerLine = "s_sh d_f1";
export var burgerMenuDesign6 = "s_sj d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "s_sk d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "s_sl d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "s_sm d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "s_sn d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "s_sp d_f9 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "s_sq d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var secondary = "s_sr d_bB d_bN";
export var staticBurger = "s_ss";
export var menu = "s_st";
export var navbarDividedLogo = "s_sv";
export var nav = "s_sw";