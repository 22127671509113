// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "f_mr";
export var warningBackgrounddark = "f_ms";
export var warningBackgroundcustom = "f_mt";
export var bottom = "f_gH";
export var modal = "f_mv";
export var container = "f_mw";
export var btnWrapper = "f_d1 d_bC d_bN d_bH";
export var elementWrapper = "f_mx d_bC d_bN d_dv";
export var titleWrapper = "f_my d_bC d_bN";
export var cookieButtons = "f_mz d_by d_bC d_bN d_bH";
export var btn = "f_mB d_by d_bC d_bN";
export var link = "f_mC";
export var decline = "f_mD f_mB d_by d_bC d_bN";
export var row = "f_mF";