import React, { useRef, useEffect, useCallback, useState } from 'react';
import clsx from 'clsx';

import * as styles from '../styles.module.css';

let IntersectionObserver;
if (typeof window !== 'undefined') {
  if (window.IntersectionObserver) IntersectionObserver = window.IntersectionObserver;
  else IntersectionObserver = require('intersection-observer'); // eslint-disable-line global-require
}

const AudioElement = ({ item, subIndex, space, isFirst = true }) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef();

  const isSoundcloud = item.content.url.toLowerCase().includes('soundcloud.com');

  const handleIntersect = useCallback((entries) => {
    if (!visible && entries?.[0]?.isIntersecting) setVisible(true);
  }, []);

  useEffect(() => {
    if (IntersectionObserver && !isFirst && isSoundcloud) {
      const observer = new IntersectionObserver((entries) => handleIntersect(entries));
      observer.observe(ref.current);
      return () => observer.disconnect();
    }

    return null;
  }, []);

  let player;
  if (isSoundcloud && (visible || isFirst)) {
    player = (
      <iframe
        width="100%"
        height={item.content.playerHeight}
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src={`https://w.soundcloud.com/player/?url=${
          item.content.url
        }&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=${
          item.content.soundcloudPlayerType === 'visual' ? 'true' : 'false'
        }`}
      />
    );
  } else if (!isSoundcloud) {
    player = (
      <audio controls style={{ width: '100%' }}>
        <source src={item.content.url} />
        <p>
          Your browser does not support HTML5 audio. <a href={item.content.url}>Download file</a>
        </p>
      </audio>
    );
  }

  return (
    <div
      ref={ref}
      className={clsx(subIndex == null && `col-10 col-lg-8 ${styles.element}`)}
      style={space ? { marginBottom: `${space}px`, marginTop: `${space}px`, width: '100%' } : undefined}
    >
      {player}
    </div>
  );
};

export default AudioElement;
