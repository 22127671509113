import React from 'react';
import { Link } from 'gatsby';
import HTMLParser from 'html-react-parser';
import { Helmet } from 'react-helmet';

import { formColor } from '../../helper';
import Image from '../Image';

import NavigationLinks from './NavigationLinks';
import * as styles from './styles.module.css';
import { InfoRowElementSetterContext } from './InfoRowHeightContext';

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    const { logo, sitename, themeData } = props.pageContext;
    const section = props.pageContext.navigation;
    const pageHeader = props.pageContext.page.header;
    const articleHeader = props.pageContext.article && props.pageContext.article.header;
    const header = articleHeader
      ? {
          ...pageHeader,
          type: articleHeader.type,
          pageBGColor: articleHeader.backgroundColor,
          pageShadow: articleHeader.shadow,
          pageLink: articleHeader.linkColor,
          pageLogo: {
            active: articleHeader.logo.active,
            logoId: articleHeader.logo.id,
            CDNLink: articleHeader.logo.CDNLink,
          },
        }
      : pageHeader;

    const burgerBreak = Number(section.styles.burger) || 5;
    const divider = section.styles.divider || 1;
    const linkStyle = section.styles.linkStyle || 1;
    const submenuStyle = section.styles.submenuStyle || 1;
    const burgerStyle = section.styles.burgerStyle || 1;
    const linkColor =
      section.styles.styles &&
      section.styles.styles.link &&
      section.styles.styles.link.active &&
      section.styles.styles.link.color
        ? section.styles.styles.link
        : undefined;

    let fontSize;
    switch (themeData.typography.logo.fontSize) {
      case 'Small':
        fontSize = '30px';
        break;
      case 'Normal':
        fontSize = '38px';
        break;
      case 'Large':
        fontSize = '50px';
        break;
      default:
        fontSize = '38px';
    }

    const logoImage = props?.pageContext?.images?.find((i) => i?._id === logo?.id);
    const logoImageType = logoImage?.mimeType;

    const scale = (logo.logoHeight || 60) / logoImage?.originalHeight;
    const logoWidth = Math.round(logoImage?.originalWidth * scale);

    this.state = {
      links: this.props.pageContext.navLinks,
      secondaryLinks: this.props.pageContext.secondaryLinks,
      burgerBreak,
      divider,
      linkStyle,
      submenuStyle,
      burgerStyle,
      recStyles: section.styles.styles,
      logo,
      sitename,
      themeData,
      overlay: section.styles.overlay,
      full: section.styles.full,
      fixed_top: section.styles.fixed_top,
      layout: section.styles.layout,
      infoRow: section.styles.infoRow,
      linkColor,
      fontSize,
      header,
      logoIsSVG: logoImageType === 'image/svg+xml',
      logoWidth,
    };

    this.navs = React.createRef();
  }

  getColor = (trgt) => {
    return formColor(
      { solid: this.state.recStyles?.[trgt]?.color },
      false,
      this.state.recStyles?.[trgt]?.opacity,
      undefined,
      this.state.themeData.colors,
      this.state.header?.type === 'invert',
    );
  };

  render() {
    const isScrolling = this.props.isScrolling && this.props.pageContext.navigation.styles.fixed_top;
    const { header, logo } = this.state;

    let [, , , color] = this.state.themeData.colors;

    if (this.state.recStyles.font.titleColor) color = this.state.recStyles.font.titleColor;
    if (color) {
      color = formColor(
        { solid: color },
        false,
        1,
        undefined,
        this.state.themeData.colors,
        header && header.type === 'invert',
      ).backgroundColor;
    }

    const titleStyle = {
      fontFamily:
        this.state.themeData.typography.logo.name !== ''
          ? this.state.themeData.typography.logo.name
          : this.state.themeData.typography.default.name,
      fontSize: this.state.fontSize,
      lineHeight: this.state.themeData.typography.logo.lineHeight
        ? this.state.themeData.typography.logo.lineHeight
        : this.state.themeData.typography.default.lineHeight,
      color,
      fontWeight: this.state.themeData.typography.logo.weight ? this.state.themeData.typography.logo.weight : 700,
      textTransform: this.state.themeData.typography.logo.textTransform
        ? this.state.themeData.typography.logo.textTransform
        : 'none',
      maxWidth: '100%',
      marginLeft: '10px',
    };

    let style;

    if (this.state.recStyles) {
      let { backgroundColor } = this.state.recStyles;
      let { opacity } = backgroundColor;
      if (
        header &&
        header.type === 'alt' &&
        header.pageBGColor &&
        header.pageBGColor.active &&
        header.pageBGColor.color
      ) {
        backgroundColor = { solid: header.pageBGColor.color };
        ({ opacity } = header.pageBGColor);
      } else if (
        this.state.recStyles.backgroundColor.solid === '' &&
        this.state.recStyles.backgroundColor.gradient.from === ''
      ) {
        backgroundColor = { solid: '#FFFFFF' };
      }

      opacity = isScrolling === false ? opacity : 1;
      const bgColor = formColor(backgroundColor, false, opacity, undefined, this.state.themeData.colors);

      const { recStyles, themeData } = this.state;
      let x;
      let y;
      let blur;
      let spread;
      let shadowColor;
      let shadowOpacity;
      let sActive = false;
      if (header && header.type === 'alt' && header.pageShadow && header.pageShadow.active) {
        ({ x, y, blur, spread, color: shadowColor, opacity: shadowOpacity } = header.pageShadow);
        sActive = true;
      } else if (recStyles.shadow.active) {
        ({ x, y, blur, spread, color: shadowColor, opacity: shadowOpacity } = recStyles.shadow);
        sActive = true;
      }

      let sColor;
      if (sActive) {
        sColor = formColor(
          { solid: shadowColor || themeData?.colors[2] || '#000000' },
          false,
          shadowOpacity,
          undefined,
          themeData.colors,
          header && header.type === 'invert',
        );
      }

      const boxShadow = sActive ? `${x}px ${y}px ${blur}px ${spread}px ${sColor.backgroundColor}` : undefined;
      const WebkitFilter = this.state.recStyles.backgroundBlur.active
        ? `blur(${this.state.recStyles.backgroundBlur.blur}px)`
        : 'none';
      const MozFilter = this.state.recStyles.backgroundBlur.active
        ? `blur(${this.state.recStyles.backgroundBlur.blur}px)`
        : 'none';
      const OFilter = this.state.recStyles.backgroundBlur.active
        ? `blur(${this.state.recStyles.backgroundBlur.blur}px)`
        : 'none';
      const msFilter = this.state.recStyles.backgroundBlur.active
        ? `blur(${this.state.recStyles.backgroundBlur.blur}px)`
        : 'none';
      const filter = this.state.recStyles.backgroundBlur.active
        ? `blur(${this.state.recStyles.backgroundBlur.blur}px)`
        : 'none';

      style = {
        boxShadow,
        WebkitFilter,
        MozFilter,
        OFilter,
        msFilter,
        filter,
      };

      if (this.state.recStyles.backgroundColor.gradient.from !== '' && this.state.recStyles.backgroundColor.active) {
        style.background = bgColor.background;
      } else if (
        this.state.recStyles.backgroundColor.active ||
        (header && header.type === 'alt' && header.pageBGColor && header.pageBGColor.active)
      ) {
        style.backgroundColor = bgColor.backgroundColor;
      }

      let solid = '#000000';
      let fOpacity = 1;
      if (header && header.type === 'alt' && header.pageLink && header.pageLink.active) {
        solid = header.pageLink.color;
        fOpacity = header.pageLink.opacity;
      } else if (this.state.recStyles.font.fontColor.color !== '') {
        solid = this.state.recStyles.font.fontColor.color;
        fOpacity = this.state.recStyles.font.fontColor.opacity;
      }

      const fontColor = formColor(
        { solid },
        undefined,
        fOpacity,
        undefined,
        this.state.themeData.colors,
        header && header.type === 'invert',
      );

      style.color = fontColor.backgroundColor;
    }

    let burgerColor;
    if (this.state.recStyles.burgerColor?.active || this.state.recStyles.burgerColor?.active == null) {
      burgerColor = this.getColor('burgerColor');
    }

    let submenuColor;
    if (this.state.recStyles.submenuBg?.active) submenuColor = this.getColor('submenuBg');
    else if (!this.state.recStyles.submenuBg && burgerColor) submenuColor = burgerColor;

    let submenuFont = style.color;
    let burgerFont = style.color;
    let submenuLink;
    let burgerLink;
    let burgerWeight;
    let submenuWeight;
    let burgerUpper;
    let submenuUpper;
    if (this.state.recStyles.submenuFont?.active) {
      submenuFont = this.getColor('submenuFont')?.backgroundColor;
      submenuWeight = this.state.recStyles.submenuFont.weight;
      submenuUpper = this.state.recStyles.submenuFont.upper;
    }
    if (this.state.recStyles.burgerFont?.active) {
      burgerFont = this.getColor('burgerFont')?.backgroundColor;
      burgerWeight = this.state.recStyles.burgerFont.weight;
      burgerUpper = this.state.recStyles.burgerFont.upper;
    }
    if (this.state.recStyles.submenuLink?.active) submenuLink = this.getColor('submenuLink')?.backgroundColor;
    if (this.state.recStyles.burgerLink?.active) burgerLink = this.getColor('burgerLink')?.backgroundColor;

    let infoRow;
    if (this.state.infoRow?.active && !this.props.noInfoRow) {
      let solid = this.state.infoRow.style.color || 'COLOR_0';
      let opacity = isScrolling === false ? this.state.infoRow.style.opacity : 1;
      let tColor;
      let textOpacity = 1;
      let lColor;
      let linkOpacity = 1;
      if (this.state.infoRow.rowType === 'light') {
        solid = 'COLOR_1';
        tColor = '#000';
        lColor = 'COLOR_3';
        opacity = 1;
      } else if (this.state.infoRow.rowType === 'dark') {
        solid = 'COLOR_3';
        tColor = '#fff';
        lColor = 'COLOR_1';
        opacity = 1;
      } else if (this.state.infoRow.rowType === 'custom' || !this.state.infoRow.rowType) {
        tColor = this.state.infoRow.style.textColor || '#fff';
        lColor = this.state.infoRow.style.linkColor || '#fff';
        textOpacity =
          this.state.infoRow.style.textOpacity !== undefined ? this.state.infoRow.style.textOpacity : textOpacity;
        linkOpacity =
          this.state.infoRow.style.linkOpacity !== undefined ? this.state.infoRow.style.linkOpacity : linkOpacity;
      }

      const infoColor = formColor(
        { solid },
        undefined,
        opacity,
        undefined,
        this.state.themeData.colors,
        header && header.type === 'invert',
      );

      const textColor = formColor(
        { solid: tColor },
        undefined,
        textOpacity,
        undefined,
        this.state.themeData.colors,
        header && header.type === 'invert',
      ).backgroundColor;

      const infoLinkColor = formColor(
        { solid: lColor },
        undefined,
        linkOpacity,
        undefined,
        this.state.themeData.colors,
        header && header.type === 'invert',
      ).backgroundColor;

      const setInfoRowElement = this.context;
      infoRow = (
        <div ref={setInfoRowElement} style={{ ...infoColor, color: textColor }} className={`infoRow ${styles.infoRow}`}>
          <Helmet>
            <style type="text/css">{`#NavigationBarAnchor a { color: ${infoLinkColor} };`}</style>
          </Helmet>
          {HTMLParser(`<span>${this.state.infoRow.text || ''}</span>`)}
        </div>
      );
    }

    let secondaryNav;
    if (this.state.secondaryLinks && this.state.secondaryLinks.length > 0) {
      secondaryNav = (
        <NavigationLinks
          links={this.state.secondaryLinks}
          color={style.color}
          color0={this.state.themeData.colors[0]}
          themeData={this.state.themeData}
          buttons={this.props.pageContext.buttons}
          images={this.props.pageContext.images}
          secondary
          linkColor={this.state.linkColor}
          header={header}
          burgerColor={burgerColor}
          submenuColor={submenuColor}
          burgerFont={burgerFont}
          submenuFont={submenuFont}
          burgerLink={burgerLink}
          submenuLink={submenuLink}
          linkStyle={this.state.linkStyle}
          burgerStyle={this.state.burgerStyle}
          submenuStyle={this.state.submenuStyle}
          burgerWeight={burgerWeight}
          burgerUpper={burgerUpper}
          submenuWeight={submenuWeight}
          submenuUpper={submenuUpper}
        />
      );
    }

    let navbar;
    let logoStyle;
    let menu;
    let noLinks;
    if (this.state.layout === 'left') {
      navbar = 'navbar';
      logoStyle = 'logoLeft';
      menu = 'menuRight';
    } else if (this.state.layout === 'center') {
      logoStyle = 'logoCenter';
      navbar = 'navbarCenter';
      menu = 'menuCenter';
    } else if (this.state.layout === 'right') {
      navbar = 'navbarReverse';
      logoStyle = 'logoRight';
      menu = 'menuLeft';
    } else if (this.state.layout === 'divided') {
      if (this.state.links && this.state.links.length > this.state.burgerBreak) {
        navbar = 'navbar';
        menu = 'menuRight';
        logoStyle = 'logoDividedBurger';
      } else {
        navbar = `navbarDivided${secondaryNav ? 'Secondary' : ''}`;

        if (this.state.links && this.state.links.length === 0) {
          noLinks = 'navbarDividedNoLinks';
        }

        menu = 'menuDivided';
        logoStyle = 'logoDivided';
      }
    } else if (this.state.layout === 'design5') {
      navbar = 'navbarDesign5';
      menu = 'menuDesign5';
    } else if (this.state.layout === 'design6') {
      navbar = 'navbarDesign6';
      menu = 'menuDesign6';
      logoStyle = 'logoDesign6';
    } else if (this.state.layout === 'design7') {
      navbar = 'navbarDesign7';
      menu = 'menuDesign7';
      logoStyle = 'logoDesign7';
    }

    let navStyle;
    if (this.state.fixed_top === false && this.state.overlay === false) {
      navStyle = 'sectionNavbar';
    } else if (this.state.fixed_top === true && this.state.overlay === false) {
      navStyle = 'sectionNavbarTop';
    } else if (this.state.fixed_top === true && this.state.overlay === true) {
      navStyle = 'sectionNavbarTopOverlay';
    } else if (this.state.fixed_top === false && this.state.overlay === true) {
      navStyle = 'sectionNavbarOverlay';
    }

    const navWrapper = this.state.full === true ? 'navbarFull' : 'navbarPartial';
    const layout = this.state.layout.charAt(0).toUpperCase() + this.state.layout.slice(1);
    const target = '/';
    const shrink = isScrolling && logo && logo.active && this.state.layout !== 'center';
    const linkStyle = shrink ? { lineHeight: 0 } : undefined;

    let logoId;
    let logoActive = false;
    if (header && header.type === 'alt' && header.pageLogo && header.pageLogo.active && header.pageLogo.logoId) {
      logoActive = true;
      ({ logoId } = header.pageLogo);
    } else if (logo && logo.active) {
      logoActive = true;
      logoId = logo.id;
    }

    const logoHeight = {
      height:
        logoActive && this.state.logoIsSVG
          ? `${logo && logo.active && logo.logoHeight !== undefined ? logo.logoHeight : 60}px`
          : 'fit-content',
      maxHeight: logoActive
        ? `${logo && logo.active && logo.logoHeight !== undefined ? logo.logoHeight : 60}px`
        : undefined,
    };

    let lg = (
      <div className={styles[logoStyle]} style={logoHeight}>
        <Link
          to={target}
          className={styles.linkStyle}
          style={{
            ...linkStyle,
            ...{ maxHeight: logoHeight.maxHeight, height: this.state.logoIsSVG ? logoHeight.height : undefined },
          }}
          draggable="false"
        >
          {logoActive && logoId && (
            <Image
              id={logoId}
              alt="Logo"
              imageStyleName={`navbarLogo${shrink ? 'Scrolling' : ''}`}
              images={this.props.pageContext.images}
              style={{
                maxHeight: logoHeight.maxHeight,
                height: this.state.logoIsSVG ? logoHeight.height : undefined,
              }}
              sizes={`${this.state.logoWidth}px`}
              loadingStrategy="eager"
            />
          )}
          {((!logoActive && this.props.pageContext.nameActive == null) || this.props.pageContext.nameActive) && (
            <span style={titleStyle}>{HTMLParser(this.state.sitename)}</span>
          )}
        </Link>
      </div>
    );

    // Domain and preview params from FE not needed
    let nav;
    if (this.state.links && this.state.links.length > 0) {
      nav = (
        <NavigationLinks
          links={this.state.links}
          burgerBreak={this.state.burgerBreak}
          burgerColor={burgerColor}
          divider={this.state.divider}
          linkStyle={this.state.linkStyle}
          layout={layout}
          menuStyle={menu}
          lg={lg}
          color={style.color}
          color0={this.state.themeData.colors[0]}
          themeData={this.state.themeData}
          images={this.props.pageContext.images}
          secondaryNav={secondaryNav}
          buttons={this.props.pageContext.buttons}
          infoRow={infoRow !== undefined}
          linkColor={this.state.linkColor}
          header={header}
          fixedOnTop={this.state.fixed_top}
          submenuColor={submenuColor}
          burgerFont={burgerFont}
          submenuFont={submenuFont}
          burgerLink={burgerLink}
          submenuLink={submenuLink}
          burgerStyle={this.state.burgerStyle}
          submenuStyle={this.state.submenuStyle}
          burgerWeight={burgerWeight}
          burgerUpper={burgerUpper}
          submenuWeight={submenuWeight}
          submenuUpper={submenuUpper}
        />
      );
    }

    if (this.state.layout === 'divided' && !(this.state.links && this.state.links.length > this.state.burgerBreak)) {
      lg = <div className={styles.divided}>{lg}</div>;
    }

    let combinedNavs;
    let topSecondary;
    if (!secondaryNav || this.state.layout === 'design5') combinedNavs = nav;
    else if (this.state.layout === 'center' || this.state.layout === 'divided') {
      combinedNavs = nav;
      let styleName = 'topSecondary';
      if (this.state.layout === 'divided' && this.state.links && this.state.links.length > this.state.burgerBreak) {
        styleName = 'topSecondaryDividedBurger';
      }
      topSecondary = <div className={styles[styleName]}>{secondaryNav}</div>;
    } else if (this.state.layout === 'left' || this.state.layout === 'right') {
      combinedNavs = (
        <div id="combinedNavs" className={styles.combinedNavs}>
          {nav}
          {secondaryNav}
        </div>
      );
    } else if (this.state.layout === 'design6') {
      combinedNavs = (
        <>
          {nav}
          <span className={styles.spacer} />
          {secondaryNav}
        </>
      );
    } else if (this.state.layout === 'design7') {
      combinedNavs = (
        <>
          {nav}
          {secondaryNav}
        </>
      );
    }

    if (this.state.layout === 'design5') {
      topSecondary = <div className={styles.menuDesign5}>{secondaryNav}</div>;
    }

    return (
      <div className={`${navStyle} ${styles[navStyle]}`} id="NavigationBarAnchor">
        {infoRow}
        <div
          className={
            styles[
              `navContainer${
                isScrolling && this.state.layout !== 'center'
                  ? 'Small'
                  : secondaryNav &&
                    (this.state.layout === 'center' ||
                      (this.state.layout === 'divided' &&
                        this.state.links &&
                        this.state.links.length < this.state.burgerBreak))
                  ? 'Secondary'
                  : ''
              }`
            ]
          }
        >
          <div style={style} className={styles.background} />
          <div ref={this.navs} className={styles[navWrapper]}>
            <div
              className={`${this.state.full ? '' : 'container'} ${styles[navbar]} ${
                noLinks !== undefined ? styles[noLinks] : ''
              }`}
            >
              {topSecondary}
              {lg}
              {combinedNavs}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NavigationBar.contextType = InfoRowElementSetterContext;

export default NavigationBar;
