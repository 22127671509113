// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "m_pW d_gv d_cr";
export var heroHeaderCenter = "m_gw d_gw d_cr d_dv";
export var heroHeaderRight = "m_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "m_pX d_gr d_cv";
export var heroParagraphCenter = "m_gs d_gs d_cv d_dv";
export var heroParagraphRight = "m_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "m_pY d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "m_pZ d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "m_p0 d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "m_p1 d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "m_p2 d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "m_p3 r_p3";
export var heroExceptionNormal = "m_p4 r_p4";
export var heroExceptionLarge = "m_p5 r_p5";
export var Title1Small = "m_p6 r_p6 r_qW r_qX";
export var Title1Normal = "m_p7 r_p7 r_qW r_qY";
export var Title1Large = "m_p8 r_p8 r_qW r_qZ";
export var BodySmall = "m_p9 r_p9 r_qW r_rd";
export var BodyNormal = "m_qb r_qb r_qW r_rf";
export var BodyLarge = "m_qc r_qc r_qW r_rg";